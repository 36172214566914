<template>
  <!--begin::Property Listing-->
  <div class="property-template">
    <!-- {{ dataLoading }} -->
    <PageHeaderCount
      moduleType="property"
      :dataLoading="dataLoading"
      :statusList="statusList"
      allkey="all_properties"
      countkey="property_status_count"
    ></PageHeaderCount>
    <ListingTemplate
      :customClass="'property-listing'"
      v-if="getPermission('property:view')"
    >
      <template v-slot:body>
        <ListingHeader>
          <template v-slot:content>
            <template v-if="selectedRows.length > 0">
              <v-flex class="pt-0 my-auto">
                <h1
                  class="form-title custom-nowrap-ellipsis margin-auto selected-rows-text"
                >
                  {{ totalSelected }} Selected
                </h1>
              </v-flex>
              <v-flex class="pt-0 justify-flex-end d-flex margin-auto">
                <template v-if="getPermission('property:update')">
                  <v-menu
                    content-class="custom-menu-list"
                    bottom
                    offset-y
                    transition="slide-y-transition"
                    :close-on-click="true"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        depressed
                        :disabled="dataLoading"
                        class="mx-2 custom-grey-border custom-bold-button"
                        color="cyan white--text"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark left>mdi-chevron-down</v-icon>Bulk Action
                      </v-btn>
                    </template>
                    <v-list>
                      <template v-for="(more, index) in propertyMoreActions">
                        <v-list-item
                          link
                          v-on:click="updateMoreAction(more.action)"
                          :key="index"
                        >
                          <v-list-item-icon class="margin-auto-zero mr-3 my-2">
                            <v-icon class="icon-default-blue">{{
                              more.icon
                            }}</v-icon>
                          </v-list-item-icon>
                          <v-list-item-title
                            class="font-weight-500 font-size-14"
                            >{{ more.title }}</v-list-item-title
                          >
                        </v-list-item>
                      </template>
                    </v-list>
                  </v-menu>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="red lighten-1 white--text"
                  v-on:click="clearSelections"
                >
                  <v-icon dark left>mdi-close</v-icon>Clear Selections
                </v-btn>
              </v-flex>
            </template>
            <template v-else>
              <v-flex class="pt-0 mxw-200 my-auto">
                <h1 class="form-title d-flex margin-auto">
                  <v-select
                    :disabled="dataLoading"
                    :items="statusList"
                    v-model="status"
                    hide-details
                    item-color="cyan"
                    class="pt-0 mt-0 listing-status main-listing-status-filter"
                    item-text="text"
                    item-value="value"
                  >
                    <template v-slot:item="{ item }">
                      <v-list-item-content>
                        <v-list-item-title
                          v-text="item.text"
                        ></v-list-item-title>
                      </v-list-item-content>
                      <v-list-item-action>
                        <v-chip
                          :color="item.color"
                          :text-color="item.textcolor"
                        >
                          <template v-if="item.value == 'all'">{{
                            item.all_properties
                          }}</template>
                          <template v-else>{{
                            item.property_status_count
                          }}</template>
                        </v-chip>
                      </v-list-item-action>
                    </template>
                  </v-select>
                </h1>
              </v-flex>
              <v-flex
                class="pt-0 justify-flex-end d-flex margin-auto all-project-btn-right"
              >
                <template v-if="getPermission('property:create')">
                  <v-btn
                    depressed
                    :disabled="dataLoading"
                    class="mx-2 custom-bold-button custom-btn-color white--text"
                    :to="getDefaultRoute('property.create')"
                  >
                    <v-icon dark left>mdi-plus</v-icon> Create
                  </v-btn>
                </template>
                <v-btn
                  depressed
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                  v-on:click="searchDialog = true"
                >
                  <v-icon dark>mdi-filter</v-icon>
                </v-btn>
                <!--  <v-btn
                  v-if="!isEngineerChannel()"
                  v-on:click="
                    $router.push(
                      getDefaultRoute('profile.setting', {
                        query: {
                          tab: 'customer',
                        },
                      })
                    )
                  "
                  :disabled="dataLoading"
                  class="mx-2 custom-grey-border custom-bold-button"
                  color="cyan white--text"
                >
                  <v-icon dark>mdi-cog</v-icon>
                </v-btn> -->
                <v-menu
                  v-if="false"
                  content-class="custom-menu-list"
                  offset-y
                  left
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="exportQuotation"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-export</v-icon> Export
                    </v-btn>
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      v-on:click="importDialog = true"
                      color="cyan white--text"
                    >
                      <v-icon dark left>mdi-database-import</v-icon> Import
                    </v-btn>
                    <!-- <v-btn
                      :disabled="dataLoading || exportLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                      :loading="exportLoading"
                    >
                      <v-icon dark>mdi-menu</v-icon>
                    </v-btn> -->
                  </template>
                  <v-list>
                    <template v-for="(item, index) in moreActions">
                      <v-list-item
                        :key="index"
                        link
                        v-on:click="moreAction(item.action, item.sort)"
                      >
                        <v-list-item-title>
                          <v-icon v-if="item.icon" left color="cyan">{{
                            item.icon
                          }}</v-icon>
                          {{ item.title }}
                        </v-list-item-title>
                      </v-list-item>
                    </template>
                  </v-list>
                </v-menu>
                <v-menu
                  max-height="400"
                  max-width="250"
                  offset-y
                  left
                  :close-on-content-click="false"
                  content-class="white-background"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      :disabled="dataLoading"
                      class="mx-2 custom-bold-button"
                      color="cyan white--text"
                      v-bind="attrs"
                      v-on="on"
                    >
                      <v-icon dark>mdi-table-edit</v-icon>
                    </v-btn>
                  </template>
                  <draggable
                    tag="ul"
                    v-model="defaultColDefs"
                    class="draggable-group"
                    handle=".draggable-drag-icon"
                    v-on:change="updateVisible()"
                  >
                    <template v-for="cols in defaultColDefs">
                      <li
                        class="draggable-group-item"
                        :key="cols.field"
                        v-if="!cols.checkbox || Number(cols.checkbox) == 0"
                      >
                        <v-checkbox
                          dense
                          v-model="defaultColShow"
                          v-bind:value="cols.field"
                          :label="cols.headerName"
                          :disabled="Number(cols.fixed) || dataLoading"
                          color="cyan"
                          hide-details
                          v-on:change="updateVisible()"
                          class="mt-0 mb-0"
                        ></v-checkbox>
                        <v-icon class="draggable-drag-icon" right color="cyan"
                          >mdi-drag</v-icon
                        >
                      </li>
                    </template>
                  </draggable>
                </v-menu>
                <v-menu offset-y left v-if="false">
                  <template v-slot:activator="{ on: menu, attrs }">
                    <v-tooltip top content-class="custom-top-tooltip">
                      <template v-slot:activator="{ on: tooltip }">
                        <v-btn
                          depressed
                          :disabled="dataLoading"
                          class="ml-2 custom-bold-button"
                          color="cyan white--text"
                          v-bind="attrs"
                          v-on="{ ...tooltip, ...menu }"
                        >
                          {{ entityObject.name }}
                        </v-btn>
                      </template>
                      <span>Entity</span>
                    </v-tooltip>
                  </template>
                  <v-list>
                    <v-list-item
                      v-for="(item, index) in entityList"
                      :key="index"
                      link
                      v-on:click="updateEntity(item)"
                    >
                      <v-list-item-title class="font-weight-600">{{
                        item.name
                      }}</v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>
                <PageTips v-if="false" module="property"></PageTips>
              </v-flex>
            </template>
          </template>
        </ListingHeader>
        <ListingSearchCriteria
          v-if="searchEnabled"
          pageModule="Site Location"
          :basicSearchFields="[
            'property_name',
            'unit_no',
            'barcode',
            'street_1',
            'street_2',
            'zip_code',
            'remarks',
          ]"
          :allowedSearchFields="searchableArray"
        ></ListingSearchCriteria>
        <perfect-scrollbar
          :options="{ suppressScrollX: true }"
          class="scroll custom-box-top-inner-shadow"
          style="max-height: 90vh; position: relative"
        >
          <ListingTable
            :columnCount="columnLength()"
            :dataLoading="dataLoading"
            :rowData="rowData"
          >
            <template v-slot:thead>
              <thead>
                <tr>
                  <template v-for="cols in customThead">
                    <th
                      v-if="cols.visible"
                      :key="cols.id"
                      class="simple-table-th"
                      :class="{
                        'checkbox-width-limit': cols.checkbox,
                        'pointer-events-none': lodash.isEmpty(rowData),
                      }"
                    >
                      <template
                        v-if="cols.checkbox && Number(cols.checkbox) == 1"
                      >
                        <v-checkbox
                          dense
                          :disabled="dataLoading"
                          v-model="selectedAll"
                          color="cyan"
                          class="hide-margin"
                          hide-details
                        ></v-checkbox>
                      </template>
                      <template v-else>
                        <div
                          v-if="cols.sortable"
                          class="simple-table-text sortable"
                          v-on:click="
                            !dataLoading
                              ? updateSorting({
                                  field: cols.field,
                                  sort: cols.sort,
                                })
                              : 'javascript:void(0)'
                          "
                        >
                          {{ cols.headerName }}
                        </div>
                        <div v-else class="simple-table-text">
                          {{ cols.headerName }}
                        </div>
                        <template v-if="cols.sort">
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'asc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-ascending</v-icon
                          >
                          <v-icon
                            class="simple-table-sort"
                            v-if="cols.sort == 'desc'"
                            small
                            right
                            color="cyan"
                            >mdi-sort-descending</v-icon
                          >
                        </template>
                      </template>
                    </th>
                  </template>
                </tr>
              </thead>
            </template>
            <template v-slot:tbody>
              <tbody class="custom-border-bottom">
                <template v-if="!lodash.isEmpty(rowData)">
                  <tr
                    v-for="(data, index) in rowData"
                    :key="index"
                    link
                    class="property-listing-row"
                    :class="{ 'table-in-active-row': !data.activated }"
                  >
                    <template v-for="cols in customThead">
                      <td
                        v-if="cols.visible"
                        :key="cols.id"
                        :class="{
                          'simple-table-td': !cols.checkbox,
                          'checkbox-width-limit': cols.checkbox,
                        }"
                        v-on:click="
                          Number(cols.checkbox) == 0
                            ? rowClicked(data)
                            : 'javascript:void(0)'
                        "
                      >
                        <template
                          v-if="cols.checkbox && Number(cols.checkbox) == 1"
                        >
                          <v-checkbox
                            dense
                            v-model="selectedRows"
                            v-bind:value="data.id"
                            color="cyan"
                            class="hide-margin"
                            hide-details
                          ></v-checkbox>
                        </template>
                        <template v-else-if="cols.field == 'barcode'">
                          <Barcode :barcode="data[cols.field]"></Barcode>
                          <br />
                          <v-chip
                            label
                            color="orange"
                            v-if="data.is_same == 1"
                            class="text-white p-3"
                            small
                            >Site Location &amp; Billing</v-chip
                          >
                          <template v-else>
                            <v-chip
                              label
                              color="orange"
                              v-if="data.type == 2"
                              outlined
                              class="text-white p-3"
                              small
                              >Billing</v-chip
                            >
                            <v-chip
                              label
                              color="green"
                              class="text-white p-3"
                              outlined
                              v-if="data.type == 1"
                              small
                              >Site Location</v-chip
                            >
                          </template>
                          <v-chip
                            label
                            color="green"
                            outlined
                            v-if="data.is_tenant"
                            >Tenant</v-chip
                          >
                        </template>
                        <template v-else-if="cols.field == 'customer_info'">
                          <div class="property-listing-customer">
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              {{ data.customer && data.customer.display_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Company: </b>
                              <template
                                v-if="
                                  data &&
                                  data.customer &&
                                  data.customer.company_name
                                "
                              >
                                {{ data.customer.company_name }}
                              </template>
                              <em v-else class="text-muted">
                                no company name</em
                              >

                              <!--  {{ data.customer && data.customer.company_name }} -->
                            </p>
                            <template v-if="!lodash.isEmpty(data.customer)">
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="!lodash.isEmpty(data.customer)"
                              >
                                <b>Email: </b>
                                <template
                                  v-if="
                                    data &&
                                    data.customer &&
                                    data.customer.company_email
                                  "
                                >
                                  {{ data.customer.company_email }}
                                </template>
                                <em v-else class="text-muted">
                                  no company email</em
                                >
                              </p>
                              <p
                                class="m-0 custom-nowrap-ellipsis"
                                v-if="!lodash.isEmpty(data.customer)"
                              >
                                <b>Phone: </b>
                                <template
                                  v-if="
                                    data &&
                                    data.customer &&
                                    data.customer.company_number
                                  "
                                >
                                  {{ data.customer.company_number }}
                                </template>
                                <em v-else class="text-muted">
                                  no company number</em
                                >
                              </p>
                            </template>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'contact_person'">
                          <div
                            v-if="data.contact_person"
                            class="property-listing-customer"
                          >
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Name: </b>
                              {{ data.contact_person.full_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Display Name: </b>
                              {{ data.contact_person.display_name }}
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Email: </b>
                              <template
                                v-if="
                                  data &&
                                  data.contact_person &&
                                  data.contact_person.primary_email
                                "
                              >
                                {{ data.contact_person.primary_email }}
                              </template>
                              <em v-else class="text-muted"> no email</em>
                              <!--   {{ data.contact_person.primary_email }} -->
                            </p>
                            <p class="m-0 custom-nowrap-ellipsis">
                              <b>Phone: </b>
                              <template
                                v-if="
                                  data &&
                                  data.contact_person &&
                                  data.contact_person.primary_phone
                                "
                              >
                                {{ data.contact_person.primary_phone }}
                              </template>
                              <em v-else class="text-muted"> no phone</em>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'zone'">
                          <div class="property-listing-customer">
                            <v-chip
                              v-if="data?.zone_color"
                              :color="data?.zone_color"
                              label
                              outlined
                              small
                              text-color=""
                              class="text-captalize"
                            >
                              {{ data?.zone_territory }}</v-chip
                            >
                            <em v-else class="text-muted"> no zone</em>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'property_info'">
                          <div
                            class="property-listing-customer"
                            v-if="lodash.isEmpty(data) === false"
                          >
                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line custom-flex-d"
                            >
                              <b>
                                <v-icon
                                  style="font-size: 18px; margin-top: -3px"
                                  color="#000"
                                  class="cmt-2"
                                >
                                  mdi-home-map-marker
                                </v-icon>
                              </b>

                              <span>
                                <template v-if="data && data.property_name">
                                  <span>
                                    {{ data.property_name }}
                                  </span>
                                </template>
                                <em v-else class="text-muted"> no Site Name</em>
                              </span>
                            </p>

                            <p
                              class="m-0 custom-nowrap-ellipsis-two-line custom-flex-d"
                            >
                              <b
                                ><v-icon
                                  style="font-size: 18px; margin-top: -3px"
                                  color="#000"
                                  class="cmt-2"
                                >
                                  mdi-map-marker
                                </v-icon>
                              </b>

                              <span>
                                {{ data.street_1 }}
                                <template v-if="data.street_2">{{
                                  data.street_2
                                }}</template>
                                <br />
                                <template v-if="data.unit_no">{{
                                  data.unit_no
                                }}</template>
                                {{ data.country }}
                                <template v-if="data.zip_code != '000000'">{{
                                  data.zip_code
                                }}</template>
                              </span>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'total'">
                          <div class="property-listing-customer">
                            <!-- <p class="m-0 custom-nowrap-ellipsis">
                              <b>Quotation: </b>
                              <span
                                class="ml-1 count-radius bg-light-success"
                                >{{ data.quotation_count || 0 }}</span
                              >
                            </p> -->
                            <p v-if="false" class="m-0 custom-nowrap-ellipsis">
                              <b>Invoice: </b>
                              <span
                                class="ml-1 count-radius bg-light-success"
                                >{{ data.invoice_count || 0 }}</span
                              >
                            </p>
                            <p class="m-1 mb-1 custom-nowrap-ellipsis-count">
                              <template v-if="data.visit_count > 0">
                                <v-badge
                                  color="red"
                                  class="mr-9 ml-2"
                                  :content="data.visit_count"
                                >
                                </v-badge>
                              </template>
                              <template v-else>
                                <v-badge
                                  color="red"
                                  content="0"
                                  class="mr-9 ml-2"
                                >
                                </v-badge>
                              </template>
                              <b>Visit </b>
                            </p>
                          </div>
                        </template>
                        <template v-else-if="cols.field == 'added_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.added_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.added_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.created_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDate(data.added_at) }}
                              {{ formatTime(data.added_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else-if="cols.field == 'updated_at'">
                          <TableActivity
                            v-if="!lodash.isEmpty(data.updated_by)"
                            :data="data"
                          >
                            <template v-slot:display_name>
                              {{ data.updated_by.display_name }}
                            </template>
                            <template v-slot:date_time>
                              <template v-if="getDateCountAll(data.added_at)">{{
                                data.modified_at
                              }}</template>
                              <template v-else>{{
                                formatDateTime(data.added_at)
                              }}</template>
                            </template>
                            <template v-slot:format_date_time>
                              {{ formatDateTime(data.updated_at) }}
                            </template>
                          </TableActivity>
                        </template>
                        <template v-else>
                          <div v-html="printRowData(cols, data)"></div>
                        </template>
                      </td>
                    </template>
                  </tr>
                </template>
                <tr v-else-if="!dataLoading">
                  <td colspan="6" class="py-4">
                    <p
                      class="m-0 row-not-found text-center font-weight-500 font-size-16"
                    >
                      <img
                        :src="$assetURL('media/error/empty.png')"
                        class="row-not-found-image"
                      />
                      Uhh... There are no site location at the moment.
                    </p>
                  </td>
                </tr>
              </tbody>
            </template>
          </ListingTable>
        </perfect-scrollbar>
        <ListingFooter
          :dataLoading="dataLoading"
          :showingFrom="showingFrom"
          :showingTo="showingTo"
          :totalRows="totalRows"
          :currentPage="currentPage"
          :totalPages="totalPages"
        ></ListingFooter>
        <Dialog :commonDialog="searchDialog">
          <template v-slot:title>Filter Site Location</template>
          <template v-slot:body>
            <perfect-scrollbar
              :options="{ suppressScrollX: true }"
              class="scroll custom-box-top-inner-shadow"
              style="max-height: 90vh; position: relative"
            >
              <v-container fluid class="pt-0 custom-search-filter">
                <v-row>
                  <template v-for="(field, index) in searchableArray">
                    <v-col md="6" :key="index" v-if="field != 'dates'">
                      <v-text-field
                        v-model.trim="listingSearch[field]"
                        dense
                        filled
                        hide-details
                        :label="getFinalLable(field, 'Site Location')"
                        solo
                        flat
                        clearable
                        @keydown.enter="filterRows"
                        color="cyan"
                      ></v-text-field>
                    </v-col>
                  </template>
                </v-row>
              </v-container>
            </perfect-scrollbar>
          </template>
          <template v-slot:action>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              color="cyan white--text"
              v-on:click="filterRows"
            >
              Filter
            </v-btn>
            <v-btn
              depressed
              :disabled="dataLoading"
              class="mx-2 custom-grey-border custom-bold-button"
              v-on:click="searchDialog = false"
            >
              Close
            </v-btn>
          </template>
        </Dialog>
        <ImportTemplate
          :dialog="importDialog"
          sample-url="public/import-samples/PropertySample.xlsx"
          api-url="address/import"
          title="Import Site Location"
          v-on:close="importDialog = false"
        ></ImportTemplate>
        <template v-if="entityDialog">
          <EntityDialog
            :entity-dialog="entityDialog"
            v-on:closeDialog="entityDialog = false"
            v-on:resetAll="entityDialog = false"
            v-on:selectEntity="selectEntity"
          ></EntityDialog>
        </template>
        <!-- <template v-if="entity && customerDialog">
          <CustomerDialog
            :entity="entity"
            :customerDialog="customerDialog"
            v-on:closeDialog="customerDialog = false"
            v-on:resetAll="customerDialog = false"
            v-on:selectCustomer="selectCustomer"
          ></CustomerDialog>
        </template> -->
      </template>
    </ListingTemplate>
  </div>
  <!--end::Property Listing-->
</template>

<script>
import draggable from "vuedraggable";
import CommonMixin from "@/core/plugins/common-mixin";
import ListingMixin from "@/core/plugins/listing-mixin";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { PATCH, DOWNLOAD } from "@/core/services/store/request.module";
import Barcode from "@/view/pages/partials/Barcode.vue";
import TableActivity from "@/view/pages/partials/Table-Activity.vue";
import ListingTemplate from "@/view/pages/partials/Listing-Template.vue";
import ListingTable from "@/view/pages/partials/Listing-Table.vue";
import ListingHeader from "@/view/pages/partials/Listing-Header.vue";
import ListingFooter from "@/view/pages/partials/Listing-Footer.vue";
import ListingSearchCriteria from "@/view/pages/partials/Listing-Search-Criteria.vue";
import Dialog from "@/view/pages/partials/Dialog.vue";

// import CustomerDialog from "@/view/pages/partials/Select-Customer.vue";
import { saveAs } from "file-saver";
import PageTips from "@/view/layout/extras/offcanvas/PageTips.vue";
import PageHeaderCount from "@/view/pages/partials/PageHeaderCount.vue";
import EntityDialog from "@/view/pages/partials/Select-Entity.vue";
import ImportTemplate from "@/view/pages/partials/Import-Template.vue";
import JwtService from "@/core/services/jwt.service";
import { ServiceFormEventBus } from "@/core/lib/service.form.lib";
import moment from "moment-timezone";
export default {
  mixins: [CommonMixin, ListingMixin],
  name: "property-list",
  data() {
    return {
      // customerDialog: false,
      exportLoading: false,
      pageModule: "property-listing",
      routeAPI: "property",
      routeName: "property",
      routeDetailName: "property.detail",
      status: "all",
      statusList: [],
      customThead: [],
      importDialog: false,
      moreActions: [
        {
          title: "Import Site Location(s)",
          action: "import_items",
          divider: true,
          icon: "mdi-database-import",
        },
        /*{
          title: "Export Site Location(s)",
          action: "export_items",
          divider: false,
          icon: "mdi-database-export",
        },*/
        /*{
          title: "Export Current View",
          action: "export_current_view",
          divider: true,
          icon: "mdi-database-export",
        },*/
        {
          title: "Refresh List",
          action: "refresh_list",
          divider: true,
          icon: "mdi-refresh",
        },
      ],
      propertyMoreActions: [
        {
          title: "Mark as Active",
          icon: "mdi-check-all",
          action: "active",
        },
        {
          title: "Mark as In-Active",
          icon: "mdi-check-all",
          action: "inactive",
        },
      ],
      entity: 1,
      entityDialog: false,
    };
  },
  components: {
    draggable,
    PageTips,
    EntityDialog,
    PageHeaderCount,
    Barcode,
    TableActivity,
    ListingTemplate,
    ListingFooter,
    ListingTable,
    ListingHeader,
    ListingSearchCriteria,
    Dialog,
    // CustomerDialog,
    ImportTemplate,
  },
  methods: {
    formatDateStartTime(row) {
      return moment(row).format("hh:mm A");
    },
    selectEntity(param) {
      this.entity = param;
      this.entityDialog = false;
      this.$nextTick(() => {
        this.customerDialog = true;
      });
    },
    selectCustomer(customer) {
      if (customer > 0) {
        this.$router.push(
          this.getDefaultRoute("property.create", {
            query: { customer },
          })
        );
      }
    },
    exportQuotation() {
      //console.log(row,"rowhhh");
      //console.log(this.searchableArray,"searchableArray");

      let downloadURL = process.env.VUE_APP_API_URL + "property/export";
      downloadURL = new URL(downloadURL);
      const token = JwtService.getToken();
      downloadURL.searchParams.append("token", token);
      //downloadURL.searchParams.append("search", this.queryParams.search);
      this.lodash.map(this.queryParams, function (row, index) {
        downloadURL.searchParams.append(index, row);
      });

      this.lodash.map(this.listingSearch, function (row, index) {
        // console.log(index, "indexdd")

        if (index == "dates") {
          if (row && row[0]) {
            downloadURL.searchParams.append("dates[0]", row[0]);
          }
          if (row && row[1]) {
            downloadURL.searchParams.append("dates[1]", row[1]);
          }
        } else {
          downloadURL.searchParams.append(index, row);
        }

        //  downloadURL.searchParams.append(index, row);
      });
      window.open(downloadURL, "_blank");
    },
    getPropertyAddress(data) {
      let result = [];
      if (this.lodash.isEmpty(data) === false) {
        if (data.unit_no) {
          result.push(data.unit_no);
        }
        if (data.street_1) {
          result.push(data.street_1);
        }
        if (data.street_2) {
          result.push(data.street_2);
        }
        if (data.zip_code) {
          result.push(data.zip_code);
        }
      }
      return result.join(", ");
    },
    moreAction(action) {
      const _this = this;
      switch (action) {
        case "import_items":
          _this.importDialog = true;
          break;
        case "export_items":
          _this.bulkPropertyExport();
          break;
        case "export_current_view":
          _this.exportCurrentView("property");
          break;
        case "refresh_list":
          _this.getRows();
          break;
      }
    },
    updateMoreAction(param) {
      const _this = this;
      switch (param) {
        case "active":
          _this.bulkUpdateProperty({
            properties: _this.selectedRows,
            status: 1,
          });
          break;
        case "inactive":
          _this.bulkUpdateProperty({
            properties: _this.selectedRows,
            status: 0,
          });
          break;
      }
    },
    bulkUpdateProperty(requestObject) {
      const _this = this;
      _this.$store
        .dispatch(PATCH, {
          url: "property",
          data: requestObject,
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.selectedRows = [];
          _this.getRows();
        });
    },
    bulkPropertyExport() {
      const _this = this;
      let fileName = new Date();
      _this.exportLoading = true;
      _this.$store
        .dispatch(DOWNLOAD, {
          url: "property/export",
        })
        .then(({ data }) => {
          saveAs(data, "property-export-" + fileName.getTime() + ".xlsx");
        })
        .catch((error) => {
          _this.logError(error);
        })
        .finally(() => {
          _this.exportLoading = false;
        });
    },
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Site Location" }]);
    ServiceFormEventBus.$on("reload:staffdata", () => {
      this.getRows();
    });
  },
  beforeMount() {
    const _this = this;
    _this.lodash.assign(_this.queryParams, _this.$route.query);

    _this.status = _this.$route.query.status || _this.status;

    /*  _this.applyColState(); */

    _this.paginationPageSize =
      window.localStorage.getItem(_this.pageModule) || 50;
  },
};
</script>
